import PropTypes from "prop-types";
// @mui
import { Box } from "@mui/material";
// config
import { HEADER, NAV } from "@/config";

// ----------------------------------------------------------------------

const SPACING = 8;

Main.propTypes = {
    sx: PropTypes.object,
    children: PropTypes.node,
};

export default function Main({ children, sx, ...other }) {
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                px: 2,
                py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
                width: {
                    xs: "100%",
                    lg: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
                },
                ...sx,
            }}
            {...other}
        >
            {children}
        </Box>
    );
}
